@keyframes ldio-mj9zj5psgn {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
}
.ldio-mj9zj5psgn div { box-sizing: border-box!important }
.ldio-mj9zj5psgn > div {
    position: absolute;
    width: 84.71px;
    height: 84.71px;
    top: 56.144999999999996px;
    left: 56.144999999999996px;
    border-radius: 50%;
    border: 5.91px solid #000;
    border-color: #3c0f0f transparent #3c0f0f transparent;
    animation: ldio-mj9zj5psgn 2s linear infinite;
}

.ldio-mj9zj5psgn > div:nth-child(2), .ldio-mj9zj5psgn > div:nth-child(4) {
    width: 68.95px;
    height: 68.95px;
    top: 64.025px;
    left: 64.025px;
    animation: ldio-mj9zj5psgn 2s linear infinite reverse;
}
.ldio-mj9zj5psgn > div:nth-child(2) {
    border-color: transparent #f91a10 transparent #f91a10
}
.ldio-mj9zj5psgn > div:nth-child(3) { border-color: transparent }
.ldio-mj9zj5psgn > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}
.ldio-mj9zj5psgn > div:nth-child(3) div:before, .ldio-mj9zj5psgn > div:nth-child(3) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 5.91px;
    height: 5.91px;
    top: -5.91px;
    left: 33.49px;
    background: #3c0f0f;
    border-radius: 50%;
    box-shadow: 0 78.8px 0 0 #3c0f0f;
}
.ldio-mj9zj5psgn > div:nth-child(3) div:after {
    left: -5.91px;
    top: 33.49px;
    box-shadow: 78.8px 0 0 0 #3c0f0f;
}

.ldio-mj9zj5psgn > div:nth-child(4) { border-color: transparent; }
.ldio-mj9zj5psgn > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}
.ldio-mj9zj5psgn > div:nth-child(4) div:before, .ldio-mj9zj5psgn > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 5.91px;
    height: 5.91px;
    top: -5.91px;
    left: 25.61px;
    background: #f91a10;
    border-radius: 50%;
    box-shadow: 0 63.04px 0 0 #f91a10;
}
.ldio-mj9zj5psgn > div:nth-child(4) div:after {
    left: -5.91px;
    top: 25.61px;
    box-shadow: 63.04px 0 0 0 #f91a10;
}
.loadingio-spinner-double-ring-yjqniluw5xl {
    width: 197px;
    height: 197px;
    display: inline-block;
    overflow: hidden;
    background: rgba(NaN, NaN, NaN, 0);
}
.ldio-mj9zj5psgn {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-mj9zj5psgn div { box-sizing: content-box; }

.img-spin{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.dual-ring {
    width: 150px;
    height: 150px;
    position: relative;
}

.dual-ring > div {
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    margin: 0;
    border-radius: 50%;
    border: 3px solid #e91e63;
    border-color: #e91e63 transparent #e91e63 transparent;
}

.dual-ring > div:nth-child(1) {
    animation: guruguru 1.2s ease-in-out infinite;
}

@keyframes guruguru {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
